<template>
  <div
      class="pageContainer app-container studentContainer"
      style="position: relative"
  >
    <el-tabs v-model="activeName" type="card" style="" @tab-click="changeTab">
      <el-tab-pane label="" name="td">
        <span slot="label">
          <i>校友活动</i>
        </span>
      </el-tab-pane>
    </el-tabs>
    <el-row>
      <el-input v-model="formData.stu_name" size="small" placeholder="活动创建人" style="max-width: 200px; margin-right: 10px;"/>
      <el-input v-model="formData.activity_name" size="small" placeholder="活动名称" style="max-width: 200px; margin-right: 10px; margin-left: 10px"/>
      <el-date-picker
          v-model="formData.date_str"
          value-format="yyyy-MM"
          type="month" size="small" style="width: 150px"
          placeholder="活动时间">
      </el-date-picker>
      <el-select v-model="formData.activity_status" placeholder="请选择审核状态" size="small"  style="max-width: 150px; margin-right: 10px; margin-left: 10px">
        <el-option label="待审核" value="0"></el-option>
        <el-option label="未通过" value="-1"></el-option>
        <el-option label="通过" value="1"></el-option>
      </el-select>
      <el-button size="small" icon="el-icon-search" @click="onSearch" plain type="primary">查询</el-button>
      <el-button size="small" icon="el-icon-refresh" @click="onReset" type="warning">重置</el-button>
    </el-row>
    <el-table stripe :data="dataList" style="width: 100%; margin: 20px 0; color: #000" border size="mini">
      <el-table-column prop="activity_name" label="活动名称" min-width="120"></el-table-column>

      <el-table-column prop="address" label="活动地址" min-width="120"></el-table-column>
      <el-table-column prop="total_people" label="计划人数" width="80"></el-table-column>
      <el-table-column prop="activity_content" label="活动内容" min-width="300"></el-table-column>
      <el-table-column prop="sta_activity_date" label="活动开始" width="150"></el-table-column>
      <el-table-column prop="sta_register_date" label="报名开始" width="150"></el-table-column>
      <el-table-column prop="end_register_date" label="报名结束" width="150"></el-table-column>
      <el-table-column prop="stu_name" label="活动创建人" width="100"></el-table-column>
      <el-table-column prop="phone" label="联系电话" min-width="100"></el-table-column>
      <el-table-column prop="activity_status" label="审核状态" min-width="120">
        <template slot-scope="scope">
          <span style="color: #67c23a;" v-if="scope.row.activity_status == 1">通过</span>
          <span style="color: #ff5500;" v-if="scope.row.activity_status == -1">未通过</span>
          <span style="color: #409eff;" v-if="scope.row.activity_status == 0">待审核</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="审核人" min-width="120"></el-table-column>
      <el-table-column prop="remark" label="评语" min-width="120"></el-table-column>
      <el-table-column fixed="right" label="操作" width="80">
        <template slot-scope="scope">
          <div class="flexStart">
            <el-button v-if="scope.row.activity_status==0" type="text" size="small" @click="review(scope.row)" style="margin: 0 !important">审核</el-button>
            <el-button v-if="scope.row.activity_status==-1" type="text" size="small" @click="review(scope.row)" style="margin: 0 !important">重新审核</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="活动审核" :visible.sync="dialogVisible" width="30%">
      <el-form ref="form" :model="ruleForm" label-width="80px">

        <el-form-item label="审核状态">
          <el-select v-model="ruleForm.activity_status" placeholder="审核状态" size="small">
            <el-option label="通过" :value="1">通过</el-option>
            <el-option label="不通过" :value="-1">不通过</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核理由">
          <el-input size="small"
              type="textarea"
              v-model="ruleForm.remark"
              placeholder="审核理由"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div class="block">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="current_page"
          :page-size="per_page"
          layout="total, prev, pager, next"
          :total="count"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      count: 0,
      current_page: 1,
      per_page: 20,
      activeName: "td",
      dataList: [],
      showSP: false,
      formData: {},
      spword: "",
      showPrint: false,
      ruleForm: {
        id: "",
        remark: "",
        activity_status: "",
      },
    };
  },
  mounted() {
    console.log(sessionStorage.getItem("auth"));
    this.getlist();
  },
  methods: {
    getlist() {
      this.$http
          .post("api/sys_activity_list", {
            page: this.current_page,
            pagesize: this.per_page,
            boole: false,
            stu_name: this.formData.stu_name,
            activity_name: this.formData.activity_name,
            activity_status: this.formData.activity_status,
            date_str:this.formData.date_str,
          })
          .then((res) => {
            console.log(res);
            this.dataList = res.data.data;
            this.count = res.data.page.count;
            this.current_page = res.data.page.current_page;
            this.per_page = res.data.page.per_page;
          });
    },
    changeTab() {
      this.getlist();
    },
    onSearch() {
      this.getlist();
    },
    onReset() {
      this.formData = {};
      this.getlist();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.current_page = val;
      this.getlist();
    },
    review(e) {
      this.ruleForm.id = e.id;
      this.ruleForm.activity_status = undefined
      this.dialogVisible = true;
    },
    onSubmit() {
      this.$http
          .post("api/check_activity", this.ruleForm)
          .then((res) => {
            this.dialogVisible = false;
            this.getlist();
          });
    },
  },
};
</script>


